import store from 'admin/store-instance';
import { UnInitializeResources } from 'utilities/errors/common';


function generateUrl(base: string, path: string = '', version = 'v1'): string {
  return `${base}/${version}${path}`;
}

function gateway(path: string = ''): string {
  const url = store.services.gatewayUrl;
  if (!url) {
    throw new UnInitializeResources(`Empty gateway url`, {url});
  }
  return generateUrl(url, path)
}

interface UrlParams {
  projectName?: string;
  path?: string;
}
const defaultUrlParams: UrlParams = {projectName: '', path: ''};

function getUrlByProject({projectName = '', path=''}: UrlParams = defaultUrlParams): string {
  if (projectName.length > 0 && !projectName.startsWith('/')) {
    projectName = `/${projectName}`;
  }
  return gateway(`/admin${projectName}${path}`);
}

function getDemo(urlParams: UrlParams = defaultUrlParams): string {
  return getUrlByProject(urlParams)
}

function getOcr(path: string = ''): string {
  return gateway(`/admin${path}`);
}

function getCompare(path: string = ''): string {
  return gateway(`/admin${path}`);
}

function getChatGpt(path: string = ''): string {
  return gateway(`/admin${path}`);
}

function getUsers(path: string = ''): string {
  return gateway(`/admin${path}`);
}

function getBilling(path: string = ''): string {
  return gateway(`/admin${path}`);
}

function getAccessTokens(path: string = ''): string {
  return gateway(`/admin${path}`);
}


function getBack(path: string = '') {
  const url = store.services.backendUrl;
  if (url == null) {
    throw new UnInitializeResources(`Empty backend url`, {url});
  }
  return generateUrl(url, `/adminportal${path}`);
}


export default {
  getBack,
  gateway,
  getUsers,
  getDemo,
  getOcr,
  getCompare,
  getChatGpt,
  getBilling,
  getAccessTokens
}