import { combineDataProviders } from 'react-admin';
import commonProvider, { DataPrefixPath } from 'providers/common-provider';
import serviceProvider from 'providers/resource-provider'
import urls from 'connections/urls';
import httpConnector from "connections/api";
import { getResourcePath } from 'admin/pages-factory';

export enum AccountsRote {
  Users   = 'users',
  Groups = 'groups',
  Permissions = 'permissions',
}

export enum DemoRoute {
  DemoWebClients = 'clients',
  DemoWebStatisticsClients = 'statistics/clients',
  DemoWebDocuments = 'documents',
  DemoWebStatisticsDocuments = 'statistics/documents',
  DemoWebResearches = 'researches',
  DemoWebStatisticsResearches = 'statistics/researches',
  DemoWebDownloads = 'downloads',
  DemoWebStatisticsDownloads = 'statistics/downloads',
  DemoWebFeedbacks = 'feedbacks',
  DemoWebStatisticsFeedbacks = 'statistics/feedbacks',
  DemoWebConfigNotifications = 'config/notifications',
  DemoWebConfigLanguages = 'config/supported-languages',
  DemoWebConfigSettings = 'config/settings',
}

export enum OcrRoute {
  PdfHistory = 'pdfs_history'
}

export enum GptChatMessagesRoute {
  GptChatMessages = 'gpt_chat_messages'
}

export enum CompareRoute {
  CompareHistory = 'compare_history'
}

export enum BillingRoute {
  Plans = 'ocr_api_plans',
  Invoices = 'ocr_api_invoices',
  Subscriptions = 'ocr_api_subscriptions',
  Subscription = 'ocr_api_subscription'
}

export enum AccessTokensRoute {
  AccessTokens = "access_tokens"
}

const gptChatPrefix: DataPrefixPath = {
  getOne: 'gpt_chat_message',
  getList: 'gpt_chat_messages'
}

export const ResourceTypes =  {
  ...AccountsRote,
  ...DemoRoute,
  ...OcrRoute,
  ...CompareRoute,
  ...GptChatMessagesRoute,
  ...BillingRoute,
  ...AccessTokensRoute
}

const genProviders = (gatewayUrls: string | null, projectName: string) => {
  if (gatewayUrls) {
    return [
      {
        routes: Object.values(AccountsRote).map(route => getResourcePath(route, 'accounts')),
        provider: commonProvider(urls.getUsers(), httpConnector)
      },
      {
        routes: [
            ...Object.values(DemoRoute).map(route => getResourcePath(route, projectName))
          ],
        provider: serviceProvider(urls.getDemo(), httpConnector)
      },
      {
        routes: [
            ...Object.values(OcrRoute).map(route => getResourcePath(route, 'ocr'))
          ],
        provider: commonProvider(urls.getOcr(), httpConnector)
      },
      {
        routes: [
            ...Object.values(GptChatMessagesRoute).map(route => getResourcePath(route, 'gpt'))
          ],
        provider: commonProvider(urls.getChatGpt(), httpConnector, gptChatPrefix)
      },
      {
        routes: [
            ...Object.values(CompareRoute).map(route => getResourcePath(route, 'compare'))
          ],
        provider: commonProvider(urls.getCompare(), httpConnector)
      },
      {
        routes: [
            ...Object.values(BillingRoute).map(route => getResourcePath(route, 'accounts'))
          ],
        provider: commonProvider(urls.getBilling(), httpConnector)
      },
      {
        routes: [
            ...Object.values(AccessTokensRoute).map(route => getResourcePath(route, 'accounts'))
          ],
        provider: commonProvider(urls.getAccessTokens(), httpConnector)
      }

    ]
  }
};

const getProvider = (gatewayUrl: string | null, projectName: string) => {
  const knownProviders = genProviders(gatewayUrl, projectName);

  return knownProviders
    ? combineDataProviders((resource) => {
        const itemProvider = knownProviders.find(routeInfo => {
            return routeInfo.routes.some(route => route === resource);
          });
        if (!itemProvider) {
          throw new Error(`Unknown resource: ${resource}`);
        }
        return itemProvider.provider;
      })
    : null;
}

export default getProvider;
