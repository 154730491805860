import { ServiceTypes } from "models/services/types";
import { ResourceElement } from "react-admin";
import buildAccountsPages from "./accounts";
import buildDemoPages from "./demo";
import mainStore from "admin/store-instance";
import buildOcrPages from "./ocr";
import buildComparePages from "./compare";
import buildGptChatPages from "./gpt-chats"


type BuildPagesFunction = (permission: any) => ResourceElement[] ;
let knownBuilderMap = new Map<ServiceTypes, BuildPagesFunction>([
  [ServiceTypes.Accounts, buildAccountsPages],
  [ServiceTypes.Demo, buildDemoPages],
  [ServiceTypes.OCR, buildOcrPages],
  [ServiceTypes.Compare, buildComparePages],
  [ServiceTypes.ChatGptMessages, buildGptChatPages]
])

export class ServicePagesFactory {
  public static createPages(serviceType: ServiceTypes, permission: any): ResourceElement[] {
    const builder = knownBuilderMap.get(serviceType);
    const result = builder?.(permission);
    return result??[];
  }
}

export function getResourcePath(name: string, basePath?: string): string {
  let path = '';
  if (basePath === undefined) {
    const {activeId} = mainStore.services;
    path = activeId
      ? activeId 
      : path;
  }
  else {
    path = basePath
  }
  if (path.length > 0) {
    path = path + '/';
  }
  const url = `${path}${name}`;
  return url;
}